import { createContext, useContext, useReducer } from "react"

type ActiveTab = "infoTask" | "infoLead" | "infoSubUser" | "infoClosure"

type SetInfoTask = { tab: "infoTask", data: { tarid: number, usuid: number, usu_usuid?: number } }
type SetInfoLead = { tab: "infoLead", data: { lead: number, usuid?: number } }
type SetInfoSubUser = { tab: "infoSubUser", data: number }
type SetInfoClosure = { tab: "infoClosure", data: { cieid: number, usuid?: number } }
type InfoModalArgs = SetInfoTask | SetInfoLead | SetInfoSubUser | SetInfoClosure

type InfoModalContextValues = {
    open: boolean
    activeTab: ActiveTab
    infoLead: { lead: number, usuid?: number }
    infoTask: { tarid: number, usuid: number, usu_usuid?: number }
    infoSubUser: number
    infoClosure: { cieid: number, usuid?: number }
    handleClose: () => void
    handleInfoModal: ({ tab, data }: InfoModalArgs) => void
}

const InfoModalContext = createContext<InfoModalContextValues | undefined>(undefined)


export const useInfoModalContext = () => {
    const context = useContext(InfoModalContext)
    if (!context) {
        throw new Error('useInfoModalContext must be used within a InfoModalProvider')
    }
    return context
}

type State = Omit<InfoModalContextValues, "handleInfoModal" | "handleClose">

type Action =
    | { type: 'SET_INFO_TASK'; payload: { tarid: number, usuid: number, usu_usuid?: number } }
    | { type: 'SET_INFO_LEAD'; payload: { lead: number, usuid?: number } }
    | { type: 'SET_INFO_SUBUSER'; payload: number }
    | { type: 'SET_INFO_CLOSURE'; payload: { cieid: number, usuid?: number } }
    | { type: 'CLEAR' }

const infoModalReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_INFO_TASK':
            return { ...state, infoTask: action.payload, open: true, activeTab: 'infoTask' };
        case 'SET_INFO_LEAD':
            return { ...state, infoLead: action.payload, open: true, activeTab: 'infoLead' };
        case 'SET_INFO_SUBUSER':
            return { ...state, infoSubUser: action.payload, open: true, activeTab: 'infoSubUser' };
        case 'SET_INFO_CLOSURE':
            return { ...state, infoClosure: action.payload, open: true, activeTab: 'infoClosure' };
        case 'CLEAR':
            return { ...state, infoTask: { tarid: 0, usuid: 0 }, infoLead: { lead: 0 }, infoSubUser: 0, open: false, activeTab: 'infoLead' }
        default:
            return state
    }
}

export const InfoModalProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(infoModalReducer, {
        open: false,
        activeTab: 'infoLead',
        infoLead: { lead: 0 },
        infoTask: { tarid: 0, usuid: 0 },
        infoSubUser: 0,
        infoClosure: { cieid: 0 }
    })

    const handleInfoModal = (args: InfoModalArgs) => {
        switch (args.tab) {
            case "infoTask":
                dispatch({ type: 'SET_INFO_TASK', payload: args.data })
                break
            case "infoLead":
                dispatch({ type: 'SET_INFO_LEAD', payload: args.data })
                break
            case "infoSubUser":
                dispatch({ type: 'SET_INFO_SUBUSER', payload: args.data })
                break
            case "infoClosure":
                dispatch({ type: 'SET_INFO_CLOSURE', payload: args.data })
                break
        }
    }

    const handleClose = () => {
        dispatch({ type: 'CLEAR' })
    }

    return (
        <InfoModalContext.Provider value={{ ...state, handleInfoModal, handleClose }}>
            {children}
        </InfoModalContext.Provider>
    )
}